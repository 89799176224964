<template>
  <el-dialog :custom-class="popClass" top="0vh" :title="titleText" :before-close="handleClose" :close-on-press-escape="false" :close-on-click-modal="false" v-model="visible" :width=popWidth>
    <div class="headNum" v-if="num !=null">已选择（{{num}}）</div>
    <slot name="el-popBody"></slot>
    <slot name="el-footer"></slot>
  </el-dialog>
</template>

<script>
import {ref,defineComponent,computed} from 'vue'
export default defineComponent({
  props:{
    popWidth:{ //弹窗宽度
      type:String,
      default:'1090px'
    },
    openhint:{ //弹窗显示隐藏
      type:Boolean,
      default:false
    },
    titleText:{ //标题
      type:String,
    },
    leftIcon :{
      type:String,
    },
    popClass:{
      type:String,
      default:"commonPop"
    },
    num:{
      type:Number,
      default:null
    }
  },
  setup(props,ctx){
    const visible = computed(() => {
      return props.openhint
    })
    const confirm=() =>{
      ctx.emit('confirm')
    }
    const handleClose = () => {
      ctx.emit('cancel')
    }
    return {
      visible,
      confirm,
      handleClose
    }
  }
})
</script>

<style lang="scss">
@import "@/styles/general/element/dialogStyle.scss";
.headNum {
  position:absolute;
  top: 19px;
  left: 115px;
  font-size: 18px
}
</style>