<template>
  <commonPop :titleText='dialogVisibletitle' :popWidth='state.popWidth' :openhint=dialogVisible
    @cancel='dialogVisible = false' class="operation_dialog">
    <template v-slot:el-popBody>
      <div class="popBody" v-if="state.batchNotice.length == 0">
        <div class="fs-20">选择导入类型</div>
        <div class="both mt-16">
          <div class="both-item" :class="[batchType == 0 ? 'activation' : '']" @click="batchType = 0">
            <el-image class="img" :src="require('@/assets/img/initiateContract/batch2.png')" />
            <span class="ml-14 fs-14">个人批量导入</span>
          </div>
          <div class="both-item" :class="[batchType == 1 ? 'activation' : '']" @click="batchType = 1">
            <el-image class="img" :src="require('@/assets/img/initiateContract/batch1.png')" />
            <span class="ml-14 fs-14">企业批量导入</span>
          </div>
        </div>
        <div class="text mt-16 fs-13 mb-50">
          先下载<span style="color:#138FFF;" class="cu-p" @click="download">《批量签署方信息模版》</span>并按照模版中的格式将信息填写完整后上传
        </div>
        <el-upload ref="uploadRef" class="upload-demo" action="#" :accept='import_accept' :http-request="xlsxHttpImport"
          multiple>
          <oabutton CSStype=2 width='9999' height=52 title='选择文件导入'></oabutton>
        </el-upload>
      </div>
      <div v-else>
        <div class="confirmbox">
          <el-table :data="state.batchNotice" class="mb-20" :header-cell-style="{
            background: '#EAEAEA',
            color: config.table.color,
            fontWeight: config.table.fontWeight,
            fontSize: config.table.fontSize,
          }" row-key="id">
            <template v-if="batchType == 0">
              <el-table-column :align="'center'" type="index" label="序号" width="80" />
              <el-table-column :align="'center'" prop="signerName" label="姓名" />
              <el-table-column :align="'center'" prop="signerPhone" label="联系方式" />
            </template>
            <template v-else>
              <el-table-column :align="'center'" type="index" label="序号" width="120" />
              <el-table-column prop="companyName" label="企业名称" />
              <el-table-column :align="'center'" prop="signerName" label="联系人" />
              <el-table-column :align="'center'" prop="signerPhone" label="联系方式" />
            </template>
          </el-table>
        </div>
        <div class="dialog-footer dp-f fd-rr mb-20" v-if="dialogType==0">
          <oabutton class="searcML" width='120' height='40' title="导入" CSStype=2 @buttonclick="submit()" />
          <oabutton class="searcML" width='120' height='40' title="重新上传" CSStype=3 @buttonclick="dialogVisible = false" />
        </div>
      </div>
    </template>
  </commonPop>
</template>
  
<script setup>
import { reactive, defineExpose, ref,defineEmits } from "vue";
import { import_accept } from "@/utils/base/accept.js";
import { readExcel } from "@/utils/server/excelFile.js"
import { handleMessage } from "@/utils/server/confirm.js"
import commonPop from "@/components/dialog/commonPop.vue";
const emit = defineEmits(['Emit'])
const state = reactive({
  popWidth: '552px',
  batchNotice: [],//批量导入数据
});
const dialogVisibletitle = ref('批量导入')//弹框标题
const dialogVisible = ref(false);//批量导入确认框
const dialogType=ref(0);//批量导入类型 0默认 1查看签署方
const batchType = ref(0);//导入类型选中 0 个人 1企业
const download = (() => {
  if (batchType.value == 0) {
    window.open("http://116.63.252.12:8866/group1/M00/00/D9/wKgAXmQub6mAQUlRAAAlWaP4yz868.xlsx?attname=客户批量数据模版.xlsx")//个人
  } else {
    window.open("http://116.63.252.12:8866/group1/M01/00/D9/wKgAXmQucQ6AWGbLAAARgyiYb_849.xlsx?attname=企业批量数据模版.xlsx")//企业
  }
})
const show = () => {
  state.popWidth = '552px'
  state.batchNotice = []
  dialogType.value=0
  dialogVisibletitle.value='批量导入'
  dialogVisible.value = true
}
const showview=(el)=>{
  state.popWidth = '1020px'
  batchType.value=el.signerType
  dialogType.value=1
  state.batchNotice = el.contractSignPersonList
  dialogVisibletitle.value='查看所有签署方('+el.contractSignPersonList.length+')'
  dialogVisible.value = true
}
// 模板文件导入
const xlsxHttpImport = ((val) => {
  // console.log('模板文件导入',val)
  readExcel(val.file).then(res => {
    console.log('模板数据',res)
    if (res.length > 0) {
      if (batchType.value == 0) {
        state.batchNotice =[]
        res.forEach(item => {
          if(item['姓名']&&item['联系方式']){
            state.batchNotice.push({
              'signerName': String(item['姓名'] || '/'),
              'signerPhone': String(item['联系方式'] || '/'),
              'type': 0,//0个人 1公司
            })
          }
        })
      } else {
        state.batchNotice =[]
        res.forEach(item => {
          if(item['企业名称']&&item['联系人']&&item['联系方式']){
            state.batchNotice.push({
              'companyName': String(item['企业名称'] || '/'),
              'signerName': String(item['联系人'] || '/'),
              'signerPhone': String(item['联系方式'] || '/'),
              'type': 1,//0个人 1公司
            })
          }
        })
      }
      let len=state.batchNotice.length
      for (let i = 0; i < len; i++) {
        for (let j = i + 1; j < len; j++) {
          if(state.batchNotice[i].type==0){
            if (state.batchNotice[i].signerPhone === state.batchNotice[j].signerPhone) {
              state.batchNotice.splice(j, 1)
              len-- // 减少循环次数提高性能
              j-- // 保证j的值自加后不变
            }
          }else{
            if (state.batchNotice[i].signerPhone === state.batchNotice[j].signerPhone&&state.batchNotice[i].companyName === state.batchNotice[j].companyName) {
              state.batchNotice.splice(j, 1)
              len-- // 减少循环次数提高性能
              j-- // 保证j的值自加后不变
            }
          }
        }
      }
      if(state.batchNotice.length==0){
        handleMessage('无符合条件数据')
        return
      }
      dialogVisibletitle.value = '将批量发起' + state.batchNotice.length + '份合同'
      state.popWidth = '1020px'
    } else {
      handleMessage('请先完成模板数据添加')
    }
  })
})
const submit = () => {
  dialogVisible.value = false
  emit('Emit',batchType.value,state.batchNotice)
}
defineExpose({
  show,showview
});
</script>
  
<style lang="scss" scoped>
@import "@/styles/color/value.scss";

.confirmbox {
  ::v-deep .el-table .el-table__cell {
    height: 50px;
  }
}

.upload-demo {
  ::v-deep .el-upload {
    width: 100% !important;

    .button {
      width: 100% !important;
    }
  }

  ::v-deep .el-upload-list {
    display: none;
  }
}

.popBody {
  padding: 0 20px 20px;

  ::v-deep .el-upload-list {
    display: none;
  }

  .both {
    display: flex;
    justify-content: space-between;

    &-item {
      box-shadow: 2px 2px 10px 0px rgba(150, 150, 150, 0.21);
      padding: 17px;
      border-radius: 9px;
      display: flex;
      align-items: center;
      border: 2px solid transparent;
      width: 222px;
      cursor: pointer;

      .img {
        width: 55px;
        height: 55px;
      }
    }

    .activation {
      background: $active-alpha;
      border: 2px solid $active-theme;
    }
  }
}
</style>