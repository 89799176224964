<template>
  <div class="w100">
    <div class="addressBox" ref="sortTablesRef">
      <!-- // type 0 通讯录添加 1手填 signerType 0 个人 1公司 -->
      <div class="addressItem pt-r" v-for="(el, ind) in state.addressList" :key="ind">
        <!-- v-if="state.uploadType != 1" -->
        <img src="@/assets/img/initiateContract/close.png" class="cu-p pt-a close"
          @click="state.addressList.splice(ind, 1)"  />
        <div class="dp-f ai-c mb-16">
          <div class="icon mr-10 dp-f">签署方{{ ind + 1 }}</div>
          <template v-if="el.type == 0">
            <div v-if="el.signerType == 0">
              {{ el.contractSignPersonList[0].signerName }}
            </div>
            <div v-else-if="el.signerType == 1">
              {{ el.contractSignPersonList[0].companyName }}
            </div>
          </template>
        </div>
        <div v-if="el.type == 2" class="label">
          签署方类型：{{ el.signerType == 0 ? "个人" : "企业" }}
        </div>
        <!-- 手填 -->
        <el-form ref="ruleFormRef" :model="state.addressList" :rules="rules" class="demo-ruleForm" label-width="0px">
          <template v-if="el.type != 2">
            <div class="dp-f ai-c mb-16" v-if="el.signerType == 1">
              <div class="w-100">企业名称：</div>
              <el-form-item :prop="ind + '.contractSignPersonList[0].companyName'" :rules="rules.companyName">
                <div class="w-460">
                  <el-input v-model="el.contractSignPersonList[0].companyName" placeholder="工商营业执照上的企业名称"
                    :maxlength="config.inputMaxlength" :disabled="el.type == 0 || el.type == 2 " @blur="checkData(el, 2, ind)" />
                    <!-- || state.uploadType == 1 -->
                </div>
              </el-form-item>
            </div>
            <div class="dp-f ai-c mb-16" v-if="el.signerType == 1">
              <div class="w-100">信用代码：</div>
              <el-form-item :prop="ind + '.contractSignPersonList[0].creditCode'" :rules="rules.creditCode">
                <div class="w-460">
                  <el-input v-model="el.contractSignPersonList[0].creditCode" placeholder="工商营业执照上的统一社会信用代码"
                    :maxlength="config.inputMaxlength" :disabled="el.type == 0 || el.type == 2 " @blur="checkData(el, 2, ind)" />
                    <!-- || state.uploadType == 1 -->
                </div>
              </el-form-item>
            </div>
            <div class="dp-f ai-c mb-16">
              <div class="w-100">签署人：</div>
              <el-form-item :prop="ind + '.contractSignPersonList[0].signerName'" :rules="rules.signerName">
                <div class="w-138 mr-10">
                  <el-input v-model="el.contractSignPersonList[0].signerName" placeholder="签署人姓名"
                    :maxlength="config.inputMaxlength" :disabled="el.type == 0 || el.type == 2 " />
                    <!-- || state.uploadType == 1 -->
                </div>
              </el-form-item>
              <el-form-item :prop="ind + '.contractSignPersonList[0].signerPhone'" :rules="rules.signerPhone">
                <div class="w-312">
                  <el-input v-model="el.contractSignPersonList[0].signerPhone" placeholder="接收方手机号"
                    :maxlength="config.inputMaxlength" :disabled="el.type == 0 || el.type == 2 " @blur="checkData(el, 2, ind)" />
                    <!-- || state.uploadType == 1 -->
                </div>
              </el-form-item>
            </div>
          </template>
          <div class="dp-f ai-c mb-16" v-if="el.signerType == 1&&(el.type != 2 || el.type != 3)">
            <div class="w-100">签署要求：</div>
            <el-form-item :prop="ind + '.requirements'" :rules="rules.requirements">
              <div class="w-460 resetCheckbox">
                <el-checkbox-group v-model="el.requirements">
                  <el-checkbox v-for="item in state.Requirements" :key="item.value" :label="item.value">{{ item.label
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </div>
          <div class="dp-f ai-c mb-16" v-if="el.type == 2 || el.type == 3">
            <div class="w-100">{{ el.type == 2 ? '批量导入' : '模板导入' }}：</div>
            <div class="label mr-50">
              已添加 <span>{{ el.contractSignPersonList.length }}</span> 个
              接收方，将批量发起
              <span>{{
                el.contractSignPersonList.length * props.filesContracts.length
              }}</span>
              份
            </div>
            <template v-if="el.type == 2">
              <div class="operation cu-p" @click="unref(importRef).showview(el)">
                查看
              </div>
              <div class="operation cu-p ml-10 mr-10">|</div>
              <div class="operation cu-p" @click="unref(importRef).show()">
                重新上传
              </div>
            </template>
            <!-- <div class="operation cu-p ml-10 mr-10">|</div>
            <div class="operation cu-p" @click="cancelBatch(el)">取消批量</div> -->
          </div>
        </el-form>
      </div>
    </div>
    <!-- v-if="state.uploadType != 1" -->
    <div class="signatory">
      <div class="btn mr-16 fs-14" @click="unref(addressbookRef).show()">
        <el-image class="icon mr-4" :src="require('@/assets/img/initiateContract/address.png')"></el-image>
        <span style="color: #be52f2">通讯录添加</span>
      </div>
      <div class="btn mr-16 bg1" @click="addressAdd(1)">
        <el-image class="icon mr-4" :src="require('@/assets/img/initiateContract/firm.png')"></el-image>
        <span style="color: #5c81ff">添加企业</span>
      </div>
      <div class="btn mr-16 bg2" @click="addressAdd(0)">
        <el-image class="icon mr-4" :src="require('@/assets/img/initiateContract/user.png')"></el-image>
        <span style="color: #1677ff">个人添加</span>
      </div>
      <template v-if="state.uploadType != 1">
        <div class="btn bg3" @click="unref(importRef).show()" v-if="importIs">
          <el-image class="icon mr-4" :src="require('@/assets/img/initiateContract/batch.png')"></el-image>
          <span style="color: #ff8100">批量导入</span>
        </div>
      </template>
    </div>
  </div>
  <batchimport ref="importRef" @Emit="batchimportEmit"></batchimport>
  <addressbook ref="addressbookRef" @Emit="addressbookEmit"></addressbook>
</template>

<script setup>
import {
  reactive,
  defineExpose,
  defineProps,
  ref,
  unref,
  nextTick,
  computed,
} from "vue";
import { handleMessage } from "@/utils/server/confirm";
import { getRequirements } from "@/utils/server/selectdata.js";
import { validPhone, validSocialCode } from "@/utils/server/validate.js";
import addressbook from "./signatory/addressbook.vue"; //通讯录添加
import batchimport from "./signatory/import.vue"; //批量导入
import { pinyin } from 'pinyin-pro';
import { useStore } from "vuex";
const store = useStore();
import Sortable from "sortablejs";
const props = defineProps({
  filesContracts: {
    type: Array,
    default() {
      return [];
    },
  },
});
const sortTablesRef = ref();
const ruleFormRef = ref(); //表单验证
const addressbookRef = ref();
const importRef = ref();
const state = reactive({
  Requirements: getRequirements(), //签署结果下拉数据
  uploadType: false,//上传类型（0 其他，1 模板）
  // type 0 通讯录添加 1手填 2批量导入 3模板
  // signerType 0个人 1公司
  addressList: [],
});
// 校验数据 个人手机号无法重复 企业 公司名称与手机号无法同时重复
const checkData = (el, type = 1, ind) => {
  for (let z = 0; z < el.contractSignPersonList.length; z++) {
    // if (type == 1 && !validPhone(el.contractSignPersonList[z].signerPhone)) {
    //   handleMessage(
    //     el.contractSignPersonList[z].signerPhone + "手机号格式错误"
    //   );
    //   return false;
    // }
    // if (type == 2 && el.contractSignPersonList[z].creditCode != ""
    //   && !validSocialCode(el.contractSignPersonList[z].creditCode)) {
    //   handleMessage(el.contractSignPersonList[z].creditCode + "信用代码格式错误")
    // }
    for (let i = 0; i < state.addressList.length; i++) {
      for (
        let j = 0;
        j < state.addressList[i].contractSignPersonList.length;
        j++
      ) {
        if (el.signerType == 0) {
          //个人
          if (
            el.contractSignPersonList[z].signerPhone !== "" &&
            state.addressList[i].signerType == 0 &&
            el.contractSignPersonList[z].signerPhone ==
            state.addressList[i].contractSignPersonList[j].signerPhone
          ) {
            if (type == 2 && ind == i) {
            } else {
              handleMessage(
                "个人用户" +
                el.contractSignPersonList[z].signerPhone +
                "手机号重复"
              );
              if (type == 2) {
                el.contractSignPersonList[z].signerPhone = "";
              }
            }
            return false;
          }
        } else {
          //公司
          if (
            el.contractSignPersonList[z].signerPhone !== "" &&
            el.contractSignPersonList[z].signerPhone ==
            state.addressList[i].contractSignPersonList[j].signerPhone &&
            el.contractSignPersonList[z].companyName ==
            state.addressList[i].contractSignPersonList[j].companyName
          ) {
            if (type == 2 && ind == i) {
            } else {
              handleMessage(
                el.contractSignPersonList[z].companyName + "公司信息重复"
              );
              if (type == 2) {
                el.contractSignPersonList[z].signerPhone = "";
                el.contractSignPersonList[z].companyName = "";
              }
            }
            return false;
          }
        }
      }
    }
  }
  return true;
};
// 取消批量点击
const cancelBatch = () => { };
// 是否展示批量导入
const importIs = computed(() => {
  let is = true;
  state.addressList.forEach((item) => {
    if (item.type == 2) {
      is = false;
    }
  });
  return is;
});
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入接收方手机号"));
  } else {
    if (validPhone(value)) {
      callback();
    } else callback(new Error("手机号格式错误"));
  }
};
// 校验手机号格式
const validCreditCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输统一社会信用代码"));
  } else {
    if (validSocialCode(value)) {
      callback();
    } else callback(new Error("统一社会信用代码格式错误"));
  }
};
const rules = reactive({
  companyName: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
  creditCode: [{ required: true, validator: validCreditCode, trigger: "blur" }],
  signerName: [
    { required: true, message: "请输入签署人姓名", trigger: "blur" },
  ],
  signerPhone: [{ validator: validPhoneData, trigger: "blur" }],
  requirements: [
    { required: true, message: "至少选择一种签署结果", trigger: "change" },
  ],
});
// 批量导入新增
const batchimportEmit = (type, data) => {
  // type 0 通讯录添加 1手填 2批量导入 3模板 // signerType 0个人 1公司
  let batchData;
  // 有数据并且通过数据校验再添加
  if (type == 0) {
    batchData = {
      type: 2,
      signerType: type,
      contractSignPersonList: [],
    };
    data.forEach((item) => {
      if (
        item.signerName != "/" &&
        item.signerPhone != "/" &&
        validPhone(item.signerPhone)
      )
        batchData.contractSignPersonList.push({
          signerName: item.signerName,
          signerPhone: item.signerPhone,
        });
    });
  } else {
    batchData = {
      type: 2,
      signerType: type,
      requirements: ["0"],
      contractSignPersonList: [],
    };
    data.forEach((item) => {
      if (
        item.signerName != "/" &&
        item.signerPhone != "/" &&
        item.companyName != "/" &&
        validPhone(item.signerPhone)
      )
        batchData.contractSignPersonList.push({
          signerName: item.signerName,
          signerPhone: item.signerPhone,
          companyName: item.companyName,
        });
    });
  }
  if (checkData(batchData)) {
    if (batchData.contractSignPersonList.length > 0) {
      // 存在批量导入的数据 替换数据 为存在再新增
      if (importIs.value == false) {
        state.addressList.forEach((item) => {
          if (item.type == 2) {
            // signerType 0个人 1公司
            if (type == 0) {
              delete item.requirements;
            } else {
              item.requirements = ["0"];
            }
            item.signerType = type;
            item.contractSignPersonList = batchData.contractSignPersonList;
          }
        });
      } else {
        state.addressList.push(batchData);
      }
    } else {
      handleMessage("无匹配数据");
    }
  }
};
// 生成拖动排序
const sortTable = () => {
  // console.log('Sortable',Sortable)
  nextTick(() => {
    new Sortable(unref(sortTablesRef), {
      group: "shared",
      animation: 150,
      // 列表排序改变
      onUpdate: function (evt) {
        // 拖动后生成新的列表
        state.addressList.splice(
          evt.newIndex,
          0,
          state.addressList.splice(evt.oldIndex, 1)[0]
        );
        let newArray = state.addressList.slice(0);
        state.addressList = [];
        nextTick(() => {
          state.addressList = newArray;
        });
      },
    });
  });
};
// sortTable();
const addressAdd = (val) => {
  if (val == 0) {
    state.addressList.push({
      type: 1,
      signerType: 0,
      contractSignPersonList: [{ signerName: "", signerPhone: "" }],
    });
  } else {
    state.addressList.push({
      type: 1,
      signerType: 1,
      requirements: ["0"],
      contractSignPersonList: [
        { signerName: "", signerPhone: "", companyName: "" },
      ],
    });
  }
};
//默认给数据
const fillIndata = () => {
  if (state.addressList == undefined || state.addressList == undefined || state.addressList.length <= 0) {
    let userJson = JSON.parse(window.sessionStorage.user)
    console.log("userjson: ", userJson)
    if (userJson.user_info.companyId) {
      state.addressList.push({
        type: 1,
        signerType: 1,
        requirements: ["0"],
        contractSignPersonList: [
          { signerName: userJson.user_info.chnname, signerPhone: userJson.user_info.phone, 
            companyName: userJson.user_info.sysCompany.companyName, creditCode: userJson.user_info.sysCompany.creditCode},
        ],
      });
    } else {
      state.addressList.push({
        type: 1,
        signerType: 0,
        contractSignPersonList: [{ signerName: JSON.parse(window.sessionStorage.user).user_info.chnname, signerPhone: JSON.parse(window.sessionStorage.user).user_info.phone, }],
      });
    }
  }
};
// 通讯录数据返回
const addressbookEmit = (data) => {
  data.forEach((item) => {
    console.log("===>item: ", item)
    let addressData;
    if (item.type == 0) {
      addressData = {
        type: 0,
        signerType: 0,
        contractSignPersonList: [
          { signerName: item.name, signerPhone: item.code },
        ],
      };
    } else if (item.type == 1) {
      addressData = {
        type: 0,
        signerType: 1,
        requirements: ["0"],
        contractSignPersonList: [
          {
            signerName: item.transactorName,
            signerPhone: item.transactorPhone,
            companyName: item.name,
            creditCode: item.code,
          },
        ],
      };
    }
    if (checkData(addressData)) {
      state.addressList.push(addressData);
    }
  });
};
// 循环校验所有表单
const submitForm = async (formEl = ruleFormRef.value) => {
  if (state.addressList.length > 0) {
    let result = true;
    if (formEl) {
      for (let i = 0; i < formEl.length; i++) {
        formEl[i].validate((valid, fields) => {
          if (!valid) {
            result = false;
          }
        });
      }
    }
    return result;
  }
};
const getData = (() => {
  console.log("getdate: ", state.addressList)
  return state.addressList
})
const setUploadType = (type) => {
  state.uploadType = type
}
// 设置模板数据 type 1模板 2批量导入
const setTemplate = (type, data) => {
  state.uploadType = type
  if (type == 1) {
    // 删除之前数据
    state.addressList.splice(0)
    // 添加默认数据
    fillIndata()
    // 添加默认甲方数据
    // if (data[0][pinyin('甲方公司名称', { toneType: 'none' })]) {
    //   state.addressList.push({
    //     type: 1,
    //     signerType: 1,
    //     requirements: ["0"],
    //     contractSignPersonList: [
    //       { signerName: data[0][pinyin('甲方姓名', { toneType: 'none' })], signerPhone: data[0][pinyin('甲方手机号', { toneType: 'none' })], companyName: data[0][pinyin('甲方公司名称', { toneType: 'none' })] },
    //     ],
    //   });
    // } else {
    //   state.addressList.push({
    //     type: 1,
    //     signerType: 0,
    //     contractSignPersonList: [{ signerName: data[0][pinyin('甲方姓名', { toneType: 'none' })], signerPhone: data[0][pinyin('甲方手机号', { toneType: 'none' })] }],
    //   });
    // }
    // type 0 通讯录添加 1手填 2批量导入 3模板
    let addressItem
    if (data[0][pinyin('乙方公司名称', { toneType: 'none' })]) {
      addressItem = {
        type: 3,
        signerType: 1,
        requirements: ["0"],
        contractSignPersonList: [],
      }
    } else {
      addressItem = {
        type: 3,
        signerType: 0,
        contractSignPersonList: [],
      }
    }
    data.forEach(item => {
      let signerName=''
      let signerPhone=''
      if(item[pinyin('乙方姓名', { toneType: 'none' })]){
        signerName=item[pinyin('乙方姓名', { toneType: 'none' })]
      }
      if(item[pinyin('乙方手机号', { toneType: 'none' })]){
        signerPhone=item[pinyin('乙方手机号', { toneType: 'none' })]
      }
      if (item[pinyin('乙方公司名称', { toneType: 'none' })]) {
        addressItem.contractSignPersonList.push({ signerName: signerName, signerPhone: signerPhone, companyName: item[pinyin('乙方公司名称', { toneType: 'none' })] })
      } else {
        addressItem.contractSignPersonList.push({ signerName: signerName, signerPhone: signerPhone })
      }
    })
    state.addressList.push(addressItem);
  }else{
    state.addressList.splice(0)
    fillIndata()
  }
}
defineExpose({
  state,
  addressList: state.addressList,
  setUploadType,
  submitForm,
  fillIndata,
  getData,
  setTemplate
});
</script>

<style lang="scss" scoped>
.operation {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #40a9ff;
}

// 多选框样式重置
.resetCheckbox {
  ::v-deep .el-checkbox__inner {
    border-radius: 4px !important;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgba(22, 119, 255, 1);
    border-color: rgba(22, 119, 255, 1);
  }

  ::v-deep .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.88);
  }
}

::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

::v-deep .el-input {
  height: 40px;
}

.addressBox {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;

  .addressItem {
    padding: 24px 30px;
    width: 100%;
    background: #ffffff;
    border-radius: 7px;
    border: 1px solid #d9d7d7;
    margin-bottom: 16px;

    .close {
      top: 20px;
      right: 16px;
    }

    .label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      span {
        color: rgba(250, 140, 21, 1);
      }
    }

    .icon {
      flex-shrink: 0;
      padding: 0px 5px;
      background: #1677ff;
      border-radius: 2px;
      height: 22px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.signatory {
  display: flex;
  position: relative;

  .right {
    position: absolute;
    right: 10px;
  }

  .btn {
    padding: 0 10px;
    height: 34px;
    display: flex;
    align-items: center;
    background-color: rgba(190, 82, 242, 0.1);
    border-radius: 4px;
    cursor: pointer;

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .bg1 {
    background-color: rgba(92, 129, 255, 0.1);
  }

  .bg2 {
    background-color: rgba(36, 132, 244, 0.1);
  }

  .bg3 {
    background-color: rgba(255, 129, 0, 0.1);
  }

  ::v-deep .el-upload {
    height: 100%;
  }
}
</style>